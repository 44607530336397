import { Injectable } from '@angular/core';
import { GlobalResponse, OptionResponse, PagedList } from '@app/models/response.model';
import {
  ChangePictureRequest,
  ChangePictureResponse,
  StaffCreateRequest,
  StaffInfoResponse,
  StaffListResponse,
  StaffNotificationResponse,
  StaffPasswordRequest,
  StaffTargetRequest,
  StaffTargetResponse,
  StaffTerminationRequest,
  StaffUpdateRequest,
} from '@modules/settings/models/staff.model';
import { Observable } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';
import { formatAPIDate } from '@app/helpers/date.helper';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  constructor(private api: ApiService) {}

  createStaff(request: StaffCreateRequest): Observable<GlobalResponse> {
    const queryRequest: StaffCreateRequest = {
      ...request,
      birthDate: formatAPIDate(new Date(request.birthDate)),
      resumptionDate: formatAPIDate(new Date(request.resumptionDate)),
    };
    return this.api.post<GlobalResponse>('staff/add', queryRequest);
  }

  updateStaff(request: StaffCreateRequest): Observable<GlobalResponse> {
    const queryRequest: StaffCreateRequest = {
      ...request,
      birthDate: formatAPIDate(new Date(request.birthDate)),
      resumptionDate: formatAPIDate(new Date(request.resumptionDate)),
    };
    return this.api.post<GlobalResponse>('staff/update', queryRequest);
  }

  getStaff(staffId: string): Observable<StaffUpdateRequest> {
    return this.api.get<StaffUpdateRequest>(`staff/${staffId}`);
  }

  getStaffTarget(staffId: string): Observable<StaffTargetResponse>{
    return this.api.get<StaffTargetResponse>(`staff/${staffId}/current-target`);
  }

  updateStaffTarget(request: StaffTargetRequest): Observable<GlobalResponse>{
    return this.api.post<GlobalResponse>('staff/update-current-target', request);
  }

  getRoles(): Observable<OptionResponse[]> {
    return this.api.get<OptionResponse[]>('staff/roles/all');
  }

  changePicture(request: ChangePictureRequest): Observable<ChangePictureResponse> {
    const formData = new FormData();
    formData.append('image', request.image);
    return this.api.postFile('staff/update-profile-image', formData);
  }

  getPagedStaff(
    pageNo: number,
    pageSize: number,
    active: boolean
  ): Observable<PagedList<StaffListResponse[]>> {
    return this.api.getPaged(
      'staff/paged',
      pageNo,
      pageSize,
      `active=${active}`
    );
  }

  getStaffInfo(): Observable<StaffInfoResponse> {
    return this.api.get(`staff/info`);
  }

  updateStaffPassword(request: StaffPasswordRequest): Observable<void> {
    return this.api.post(`staff/update-password`, request);
  }

  terminateStaff(request: StaffTerminationRequest): Observable<void> {
    return this.api.post(`staff/terminate`, request);
  }

  activateStaff(staffId: string): Observable<void> {
    return this.api.post(`staff/activate`, { staffId });
  }

  getPagedNotifications(pageNo: number, pageSize: number): Observable<PagedList<StaffNotificationResponse[]>> {
    return this.api.getPaged<PagedList<StaffNotificationResponse[]>>(`staff/notifications/paged`, pageNo, pageSize)
  }

  getLatestNotifications(): Observable<StaffNotificationResponse[]> {
    return this.api.get("staff/notifications/latest")
  }
}
